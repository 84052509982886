import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import TableComponent from "../components/serial/table";
import DownloadIcon from "@mui/icons-material/Download";
import useSerial from "../hooks/useSerial";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { GetSerialListResponseData } from "../apis/serial";
import QrCodeCell from "../components/serial/qrCodeCell";
import QrCode2Icon from "@mui/icons-material/QrCode2";

export interface Column {
  id: string;
  label?: string;
  width?: number | string;
  align?: "right" | "left" | "center";
  eclipse?: boolean;
  isCopy?: boolean;
  orderAble?: boolean;
  format?: (value: any, row?: GetSerialListResponseData) => any;
}

const columns: Column[] = [
  {
    id: "checkbox",
    width: 10,
    align: "center",
  },
  {
    id: "no",
    label: "No.",
    width: 20,
    align: "center",
  },
  {
    id: "fd_type",
    label: "Type",
    width: 50,
    align: "center",
    orderAble: true,
  },
  {
    id: "fd_sr_code",
    label: "Serial Code",
    width: 150,
    isCopy: true,
    eclipse: true,
    align: "center",
    orderAble: true,
  },
  {
    id: "fd_limit",
    label: "Guest Limit",
    width: 100,
    align: "center",
    orderAble: true,
  },
  {
    id: "fd_site_domain",
    label: "Company",
    width: 200,
    align: "center",
    orderAble: true,
  },
  {
    id: "fd_access_url",
    label: "Domain",
    width: 150,
    align: "center",
    isCopy: true,
    eclipse: true,
    orderAble: true,
  },
  {
    id: "fd_reg_date",
    label: "Registration",
    width: 120,
    align: "center",
    orderAble: true,
    format: (value: Date) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    id: "fd_expire",
    label: "Expire",
    width: 120,
    align: "center",
    orderAble: true,
    format: (value: Date) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    id: "qrcode",
    label: "QR Code",
    width: 190,
    align: "center",
    format: (value: any, row?: GetSerialListResponseData) => (
      <QrCodeCell row={row} type="guest" />
    ),
  },
];

const Quest = () => {
  const {
    rows,
    page,
    type,
    limit,
    search,
    order,
    checkRows,
    totalCount,
    setType,
    setOrder,
    handleCopy,
    handleStatus,
    handleChecked,
    handleDeleteRow,
    handleSearchText,
    handleChangePage,
    handleCheckedAll,
    handleChangeLimit,
    handleExcelDownload,
    handleEditModalOpen,
    handleResetCheckRows,
    handleDownloadQrCodeMany,
  } = useSerial({ target: "guest" });

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, backgroundColor: "#f8f9fa", p: 12 }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "30px", marginBottom: 5 }}
        >
          Serial Codes(Guest)
        </Typography>
      </div>
      <div style={styles.tool}>
        <TextField label="Search" value={search} onChange={handleSearchText} />

        <IconButton onClick={handleExcelDownload}>
          <DownloadIcon />
        </IconButton>
      </div>
      <div
        style={{
          width: "100%",
          maxWidth: 1500,
          overflowX: "scroll",
        }}
      >
        <TableComponent
          columns={columns}
          rows={rows}
          handleDeleteRow={handleDeleteRow}
          handleEditModalOpen={handleEditModalOpen}
          handleStatus={handleStatus}
          page={page}
          limit={limit}
          handleChangePage={handleChangePage}
          handleChangeLimit={handleChangeLimit}
          order={order}
          setOrder={setOrder}
          type={type}
          setType={setType}
          totalCount={totalCount}
          handleCopy={handleCopy}
          handleCheckedAll={handleCheckedAll}
          handleChecked={handleChecked}
          checkRows={checkRows}
          handleResetCheckRows={handleResetCheckRows}
        />
      </div>
      {checkRows.length ? (
        <>
          <Fab
            onClick={() => handleDownloadQrCodeMany("guest")}
            color="success"
            aria-label="qrcode"
            sx={{ position: "fixed", right: 50, bottom: 50 }}
          >
            <QrCode2Icon />
          </Fab>
        </>
      ) : null}
    </Box>
  );
};

export default Quest;

const styles = {
  tool: {
    marginBottom: 15,
    display: "flex",
    justifyContent: "space-between",
  },
  searchArea: {},
  searchInput: {},
};
