import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { ChangeEventHandler } from "react";

interface CreateDialogComponentProps {
  open: boolean;
  Transition: any;
  handleClose: any;
  makeSerial: any;
}

const CreateDialogComponent = (props: CreateDialogComponentProps) => {
  const [company, setCompany] = React.useState("");
  const [count, setCount] = React.useState("1");
  const [limit, setLimit] = React.useState("1");
  const [expire, setExpire] = React.useState("1");
  const handleCompany:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined = (event) => {
    const { value = "" } = event.target;
    setCompany(value.replace(/[^a-zA-Z0-9-_]/g, "").replace(/\s/g, ""));
  };

  const handleCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCount(event.target.value.replace(/[^0-9]/g, ""));
  };

  const handleLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setLimit(event.target.value.replace(/[^0-9]/g, ''));
    setLimit("1");
  };

  const handleExpire = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExpire(event.target.value.replace(/[^0-9]/g, ""));
  };
  return (
    <Dialog
      open={props.open}
      TransitionComponent={props.Transition}
      keepMounted
      onClose={props.handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Create Admin"}</DialogTitle>
      <DialogContent>
        <div id="alert-dialog-slide-description">
          <InputLabel id="demo-simple-select-standard-label">
            Company
          </InputLabel>
          <TextField
            id="Company"
            type="text"
            variant="standard"
            value={company}
            onChange={handleCompany}
            sx={{ width: "100%", marginBottom: 3 }}
          />
          <TextField
            id="COUNT"
            type="text"
            label="Count"
            variant="standard"
            value={count}
            onChange={handleCount}
            sx={{ width: "100%", marginBottom: 3 }}
          />
          <TextField
            id="GUEST_LIMIT"
            type="text"
            label="Limit of Guests"
            variant="standard"
            value={limit}
            onChange={handleLimit}
            sx={{ width: "100%", marginBottom: 3 }}
            aria-readonly={"true"}
          />
          <TextField
            id="EXPIRE"
            type="text"
            label="Expire date (1 = 1month)"
            variant="standard"
            value={expire}
            onChange={handleExpire}
            sx={{ width: "100%", marginBottom: 3 }}
          />
          <FormControl
            variant="standard"
            sx={{ width: "100%", marginBottom: 2 }}
          >
            <Button
              variant="contained"
              sx={{ marginTop: 4 }}
              onClick={() =>
                props.makeSerial({ company, limit, count, expire })
              }
            >
              Add
            </Button>
          </FormControl>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateDialogComponent;
