import axiosInstance from "./axiosInstance";

export interface PostAdminLoginRequest {
  username?: string;
  pw?: string;
}

export interface PostAdminLoginResponse {
  result: boolean;
  access_token?: string;
  message?: string;
}

export const postAdminLogin = (payload: PostAdminLoginRequest) => {
  return axiosInstance.post<PostAdminLoginResponse>("/admin/login", payload);
};

// 삭제 예정
export const handleLogout = () => {
  // sessionDelete();
  window.location.href = "/";
};
