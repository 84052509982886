import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import dayjs from "dayjs";
import JSZip from "jszip";
import saveAs from "file-saver";
import html2canvas from "html2canvas";
import {
  deleteSerial,
  editSerial,
  EditSerialRequest,
  getSerialList,
  GetSerialListRequestOrder,
  GetSerialListRequestType,
  GetSerialListResponseData,
  makeSerial,
  MakeSerialRequest,
} from "../apis/serial";
import { useCommonContext } from "../context/commonContext";

export interface UseSerialArgs {
  target: "host" | "guest";
}

const useSerial = ({ target }: UseSerialArgs) => {
  // 시리얼 목록
  const [rows, setRows] = useState<GetSerialListResponseData[]>([]);

  console.log('rows :', rows );
  // 시리얼 추가 모달 관련 state
  const [open, setOpen] = useState(false);

  // 시리얼 수정 모달 관련 state
  const [isOpenEditModal, setIsEditModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] =
    useState<GetSerialListResponseData | null>(null);

  // 페이지네이션 관련 state
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalCount, setTotalCount] = useState(0);
  const [order, setOrder] = useState<GetSerialListRequestOrder>("desc");
  const [type, setType] = useState<GetSerialListRequestType>("id");
  const [search, setSearch] = useState<string>("");

  // 체크박스 관련 state
  const [checkRows, setCheckRows] = useState<string[]>([]);
  const [isQrCodeDownload, setIsQrCodeDownload] = useState(false);

  // Context
  const { setIsBackdrop } = useCommonContext();

  const handleResetCheckRows = () => setCheckRows([]);

  const handleCheckedAll:
    | MouseEventHandler<HTMLTableCellElement>
    | undefined = () => {
    if (rows.length === checkRows.length) {
      setCheckRows([]);
    } else {
      setCheckRows(rows.map((row) => row.fd_sr_code));
    }
  };

  const handleChecked = (fd_sr_code: string) => {
    setCheckRows((prev) =>
      prev.includes(fd_sr_code)
        ? prev.filter((checkRows) => checkRows !== fd_sr_code)
        : [...prev, fd_sr_code],
    );
  };

  const handleCopy = (value: any) => {
    if (typeof navigator.clipboard === "undefined") {
      console.log("navigator.clipboard");
      const textArea = document.createElement("textarea");
      textArea.value = value;
      textArea.style.display = "none";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        alert("Copied.");
      } catch (error) {
        console.log(error);
      }

      document.body.removeChild(textArea);
      return;
    }
    navigator.clipboard.writeText(value).then(
      () => {
        alert("Copied.");
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleExcelDownload = () => {
    const excelFileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const excelFileExtension = ".xlsx";
    const current = new Date();
    const excelFileName = `Any QR Chat Admin_Serial Code_${current.getFullYear()}${current.getMonth() + 1 < 10 ? "0" + current.getMonth() + 1 : current.getMonth() + 1}${current.getDate()}${current.getSeconds()}`;

    const workSheet = XLSX.utils.aoa_to_sheet([
      [
        "No.",
        "Type",
        "Serial Code",
        "Guest Limit",
        "Company",
        "Domain",
        "Registration",
        "Expire",
      ],
    ]);

    let count = 0;
    const dataInsertCallback = (
      row: GetSerialListResponseData,
      index: number,
    ) => {
      XLSX.utils.sheet_add_aoa(
        workSheet,
        [
          [
            count - index,
            row.fd_type,
            row.fd_sr_code,
            row.fd_limit,
            row.fd_site_domain,
            row.fd_access_url,
            dayjs(row.fd_reg_date).format("YYYY-MM-DD HH:mm:ss"),
            dayjs(row.fd_expire).format("YYYY-MM-DD HH:mm:ss"),
          ],
        ],
        {
          origin: -1,
        },
      );
      workSheet["!cols"] = [{ wpx: 200 }, { wpx: 200 }];
      return false;
    };

    if (checkRows.length) {
      count = checkRows.length;
      rows
        .filter((row) => checkRows.includes(row.fd_sr_code))
        .map(dataInsertCallback);
    } else {
      count = rows.length;
      rows.map(dataInsertCallback);
    }

    const workBook: any = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    const excelButter = XLSX.write(workBook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelFile = new Blob([excelButter], { type: excelFileType });

    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);

    alert("엑셀 다운로드가 완료되었습니다.");
    setCheckRows([]);
  };

  const createQrCodeFile = async ({
    id,
    filename,
  }: {
    id: string;
    filename: string;
  }): Promise<
    | {
        file: File;
        blob: Blob;
      }
    | undefined
  > => {
    try {
      const div = document.getElementById(id);
      if (div) {
        const canvas = await html2canvas(div, { scale: 2 });
        return new Promise((resolve) => {
          canvas.toBlob((blob) => {
            if (blob !== null) {
              const file = new File([blob], filename, { type: "image/png" });
              resolve({ file, blob });
            } else {
              resolve(undefined);
            }
          });
        });
      }
      return undefined;
    } catch (error) {
      console.error("Error converting div to image:", error);
      return undefined;
    }
  };

  const handleDownloadQrCodeOne = async ({
    id,
    filename,
  }: {
    id: string;
    filename: string;
  }) => {
    try {
      const qrcode = await createQrCodeFile({ id, filename });

      if (!qrcode) return null;

      const { blob } = qrcode;
      saveAs(blob, filename);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadQrCodeMany = async (type: "host" | "guest") => {
    try {
      setIsBackdrop(true);
      const qrCodeValues = rows.filter((row) =>
        checkRows.includes(row.fd_sr_code),
      );

      let fileNames: string[] = [];
      const zip = new JSZip();
      for (const row of qrCodeValues) {
        const length = fileNames.filter(
          (filename) => filename === `QR Code-${row.fd_site_domain}.png`,
        ).length;

        const filename = length
          ? `QR Code-${row.fd_site_domain} (${length + 1}).png`
          : `QR Code-${row.fd_site_domain}.png`;

        const qrcode = await createQrCodeFile({
          id: `qrcode-${type}-${row.fd_sr_code}`,
          filename,
        });
        if (qrcode) {
          zip.file(filename, qrcode.file);
        }
        fileNames.push(`QR Code-${row.fd_site_domain}.png`);
      }
      zip.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, `ACE BIZ_QR Code_${dayjs().format("YYYYMMDDHHmmss")}.zip`);
        setIsBackdrop(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteRow = async (qr_code: string) => {
    if (!window.confirm("Are you sure you want to delete?")) return;

    try {
      const {
        data: { result },
      } = await deleteSerial({ qr_code });

      if (result) {
        alert("Success.");
        window.location.reload();
      } else {
        alert("Fail.");
      }
    } catch (error) {
      console.log(error);
      alert("Fail.");
    } finally {
      setIsQrCodeDownload(false);
    }
  };

  const handleDeleteMany: MouseEventHandler<HTMLButtonElement> = async (
    event,
  ) => {
    event.preventDefault();
    if (!window.confirm("Are you sure you want to delete?")) return;

    try {
      const {
        data: { result },
      } = await deleteSerial({ qr_code: checkRows.join(",") });

      if (result) {
        alert("Success.");
        window.location.reload();
      } else {
        alert("Fail.");
      }
    } catch (error) {
      console.log(error);
      alert("Fail.");
    }
  };

  const handleEditModalOpen = (row: any) => {
    setIsEditModalOpen(true);
    setSelectedRow(row);
  };

  const handleEditSerial: MouseEventHandler<HTMLButtonElement> = async (
    event,
  ) => {
    event.preventDefault();

    if (!window.confirm("Are you sure you want to edit?")) return;

    if (!selectedRow?.fd_sr_code?.trim()) {
      alert("Please, input code.");
      return;
    }

    if (!selectedRow?.fd_site_domain?.trim()) {
      alert("Please, input company");
      return;
    }

    if (!selectedRow?.fd_expire) {
      alert("Please, input expire");
      return;
    }

    if (!selectedRow?.fd_status?.trim()) {
      alert("Please, input status");
      return;
    }

    try {
      const {
        data: { result },
      } = await editSerial({
        qr_code: selectedRow?.fd_sr_code || "",
        company: selectedRow?.fd_site_domain || "",
        expire: new Date(selectedRow?.fd_expire || "")
          .toISOString()
          .replace(/T/g, " ")
          .replace("Z", ""),
        status: selectedRow.fd_status || "active",
      });

      if (result) {
        alert("Success.");
        window.location.reload();
      } else {
        alert("Fail.");
      }
    } catch (error) {
      console.log(error);
      alert("Fail.");
    } finally {
      setIsEditModalOpen(false);
    }
  };

  const handleStatus = async (payload: EditSerialRequest) => {
    try {
      const {
        data: { result },
      } = await editSerial(payload);

      if (result) {
        setRows((prev) =>
          prev.map((row) =>
            row.fd_sr_code === payload.qr_code
              ? {
                  ...row,
                  fd_status: payload.status,
                }
              : row,
          ),
        );
      } else {
        alert("Fail.");
      }
    } catch (error) {
      console.log(error);
      alert("Fail.");
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    event?.preventDefault();
    setPage(page);
    setCheckRows([]);
  };

  const handleChangeLimit:
    | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = (event) => {
    event.preventDefault();

    const { value } = event.target;
    setLimit(Number(value));
    setPage(0);
    setCheckRows([]);
  };

  const handleSearchText: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    event.preventDefault();

    const { value } = event.target;
    setSearch(value);
  };

  const fetchSerialList = useCallback(async () => {
    try {
      const {
        data: { data, total_count },
      } = await getSerialList({
        target,
        page: page + 1,
        limit,
        order,
        type,
        search,
      });
      setRows(data);
      setTotalCount(total_count);
    } catch (error) {
      console.log(error);
    }
  }, [page, limit, type, order, search, target]);

  const handleMakeSerial = async (payload: MakeSerialRequest) => {
    try {
      const {
        data: { message, result },
      } = await makeSerial(payload);
      alert(message);
      if (result) window.location.href = "/serial";
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchSerialList();
    })();
  }, [fetchSerialList]);

  return {
    open,
    rows,
    page,
    type,
    limit,
    order,
    search,
    checkRows,
    totalCount,
    selectedRow,
    isOpenEditModal,
    isQrCodeDownload,
    setType,
    setRows,
    setOrder,
    handleCopy,
    handleClose,
    handleStatus,
    handleChecked,
    setSelectedRow,
    handleDeleteRow,
    handleClickOpen,
    handleDeleteMany,
    handleEditSerial,
    handleChangePage,
    handleMakeSerial,
    handleCheckedAll,
    handleSearchText,
    handleChangeLimit,
    setIsEditModalOpen,
    handleExcelDownload,
    handleEditModalOpen,
    handleResetCheckRows,
    handleDownloadQrCodeOne,
    handleDownloadQrCodeMany,
  };
};

export default useSerial;
