import "./App.css";
import { BrowserRouter } from "react-router-dom";
import NavigationComponent from "./components/common/navigation";
import SideMenuBar from "./components/common/sidemenu";
import { Backdrop, Box, CircularProgress, CssBaseline } from "@mui/material";
import Pages from "./pages";
import {
  CommonContextProvider,
  useCommonContext,
} from "./context/commonContext";

const Contents = () => {
  const { isBackdrop } = useCommonContext();

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <NavigationComponent />
        <SideMenuBar />
        <Pages />
      </Box>
      <Backdrop
        open={isBackdrop}
        sx={{
          zIndex: 9999,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

function App() {
  return (
    <CommonContextProvider>
      <BrowserRouter>
        <Contents />
      </BrowserRouter>
    </CommonContextProvider>
  );
}

export default App;
