import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { ChangeEventHandler } from "react";
import dayjs from "dayjs";

interface EditDialogComponentProps {
  open: boolean;
  Transition: any;
  handleClose: any;
  editSerial: any;
  serial: any;
  setSelectedRow: any;
}

const EditDialogComponent = ({
  open,
  Transition,
  handleClose,
  editSerial,
  serial,
  setSelectedRow,
}: EditDialogComponentProps) => {
  const handleCompany:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined = (event) => {
    const { value = "" } = event.target;
    setSelectedRow((prev: any) => ({
      ...prev,
      fd_site_domain: value.replace(/[^a-zA-Z0-9-_]/g, "").replace(/\s/g, ""),
    }));
  };

  const handleExpire = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value = "" } = event.target;

    setSelectedRow((prev: any) => ({
      ...prev,
      fd_expire: value.trim(),
    }));
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Edit Serial</DialogTitle>
      <DialogContent>
        <div id="alert-dialog-slide-description">
          <InputLabel id="demo-simple-select-standard-label">
            Company
          </InputLabel>
          <TextField
            id="Company"
            type="text"
            variant="standard"
            value={(serial?.fd_site_domain || "").toString().substring(0, 16)}
            onChange={handleCompany}
            sx={{ width: "100%", marginBottom: 3 }}
          />
          <TextField
            id="EXPIRE"
            type="datetime-local"
            label="Expire date"
            variant="standard"
            value={dayjs(serial?.fd_expire || new Date()).format(
              "YYYY-MM-DD HH:mm:ss",
            )}
            onChange={handleExpire}
            sx={{ width: "100%", marginBottom: 3 }}
          />
          <FormControl
            variant="standard"
            sx={{ width: "100%", marginBottom: 2 }}
          >
            <Button
              variant="contained"
              sx={{ marginTop: 4 }}
              onClick={editSerial}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ marginTop: 1 }}
              onClick={handleClose}
            >
              Close
            </Button>
          </FormControl>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditDialogComponent;
