import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import DialogComponent from "../components/admin/dialog";
import TableComponent from "../components/admin/table";
import Transition from "../components/Transition";
import useModal from "../hooks/useModal";

const Admin = () => {
  const { isOpen, handleModalOpen, handleModalClose } = useModal();

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, backgroundColor: "#f8f9fa", p: 12, height: "100vh" }}
    >
      <Typography sx={{ fontWeight: "bold", fontSize: "30px" }}>
        Admin
      </Typography>
      <TableComponent />
      <Fab
        onClick={handleModalOpen}
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", right: 50, bottom: 50 }}
      >
        <Add />
      </Fab>
      <DialogComponent
        isOpen={isOpen}
        handleModalClose={handleModalClose}
        Transition={Transition}
      />
    </Box>
  );
};

export default Admin;
