import useSerial from "../../hooks/useSerial";
import QRCode from "qrcode.react";
import { GetSerialListResponseData } from "../../apis/serial";
import { MouseEventHandler, ReactNode } from "react";

interface CircleProps {
  children: ReactNode;
}

const Circle = ({ children }: CircleProps) => {
  return (
    <div style={{ position: "relative", width: "190px", height: "190px" }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          border: "5px solid black",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export interface QrCodeCellProps {
  row?: GetSerialListResponseData;
  type: "host" | "guest";
}

const QrCodeCell = ({ row, type }: QrCodeCellProps) => {
  const { handleDownloadQrCodeOne } = useSerial({ target: type });

  const handleClick: MouseEventHandler = async (event) => {
    event.preventDefault();

    if (!row) return;

    try {
      await handleDownloadQrCodeOne({
        id: `qrcode-${type}-${row.fd_sr_code}`,
        filename: `QR Code-${row.fd_site_domain}.png`,
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (row && row.fd_access_url) {
    return (
      <div
        id={`qrcode-${type}-${row.fd_sr_code}`}
        onClick={handleClick}
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
        }}
      >
        {type === "host" ? (
          <Circle>
            <QRCode value={row.fd_access_url} size={150} />
          </Circle>
        ) : (
          <QRCode value={row.fd_access_url} size={150} />
        )}

        <div
          style={{
            width: type === "host" ? 190 : 150,
            overflowX: "hidden",
            overflowWrap: "break-word",
          }}
        >
          {row.fd_site_domain}
        </div>
      </div>
    );
  }

  return null;
};

export default QrCodeCell;
