import axiosInstance from "./axiosInstance";
import { items } from "../interfaces/admin";

export interface PostAdminRequest {
  username?: string;
  pw?: string;
  pw_check?: string;
  company?: string;
  level?: string;
}

export interface PostAdminResponse {
  result: boolean;
  message: string;
}

export const postAdmin = ({ pw_check, ...payload }: PostAdminRequest) => {
  return axiosInstance.post<PostAdminResponse>("/admin", payload);
};

export const listAdmin = async () => {
  const result = await axiosInstance.get("/admin");
  const res: items = JSON.parse(JSON.stringify(result.data.data));
  console.log(res);
  return res;
};

export const deleteAdmin = (params: any) => {
  alert("delete admin");
};

export const inactiveAdmin = async (params: any) => {
  const result = await axiosInstance.put("/admin", {
    status: params.status,
    username: params.username,
  });

  return result;
};
