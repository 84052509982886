import { MouseEventHandler, useState } from "react";

const useModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleModalOpen:
    | MouseEventHandler<HTMLButtonElement>
    | undefined = () => {
    setIsOpen(true);
  };

  const handleModalClose:
    | MouseEventHandler<HTMLButtonElement>
    | undefined = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    handleModalOpen,
    handleModalClose,
  };
};

export default useModal;
