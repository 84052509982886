export const getSerialTypeMapping = (type: string) => {
  switch (type) {
    case "fd_type":
      return "type";
    case "fd_sr_code":
      return "sr_code";
    case "fd_limit":
      return "limit";
    case "fd_site_domain":
      return "site_domain";
    case "fd_access_url":
      return "access_url";
    case "fd_reg_date":
      return "reg_date";
    case "fd_expire":
      return "expire";
    case "fd_status":
      return "status";
    default:
      return "id";
  }
};
