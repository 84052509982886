import { Column } from "../../pages/serial";
import {
  GetSerialListRequestOrder,
  GetSerialListRequestType,
} from "../../apis/serial";
import * as React from "react";
import { MouseEventHandler } from "react";
import { getSerialTypeMapping } from "../../utils/utils";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SwapVertIcon from "@mui/icons-material/SwapVert";

interface TableHeaderColumnOrderProps {
  column: Column;
  order: GetSerialListRequestOrder;
  setOrder: React.Dispatch<React.SetStateAction<GetSerialListRequestOrder>>;
  type: GetSerialListRequestType;
  setType: React.Dispatch<React.SetStateAction<GetSerialListRequestType>>;
  handleResetCheckRows: () => void;
}

const TableHeaderColumnOrder = ({
  column,
  type,
  order,
  setOrder,
  setType,
  handleResetCheckRows,
}: TableHeaderColumnOrderProps) => {
  const handleDefaultOrder: MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    setType(getSerialTypeMapping(column.id));
    setOrder("desc");
    handleResetCheckRows();
  };

  const handleDescOrder: MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    setOrder("asc");
    handleResetCheckRows();
  };

  const handleAscOrder: MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    setOrder("desc");
    handleResetCheckRows();
  };

  if (!column?.orderAble) return null;

  if (getSerialTypeMapping(column.id) === type && order === "desc") {
    return (
      <KeyboardArrowUpIcon
        onClick={handleDescOrder}
        style={styles.orderButton}
      />
    );
  }

  if (getSerialTypeMapping(column.id) === type && order === "asc") {
    return (
      <KeyboardArrowDownIcon
        onClick={handleAscOrder}
        style={styles.orderButton}
      />
    );
  }

  return (
    <SwapVertIcon onClick={handleDefaultOrder} style={styles.orderButton} />
  );
};

export default TableHeaderColumnOrder;
const styles = {
  orderButton: {
    cursor: "pointer",
  },
};
