import axiosInstance from "./axiosInstance";

export interface MakeSerialRequest {
  company: string;
  limit: string;
  count: string;
  expire: string;
}

export interface MakeSerialResponse {
  result: boolean;
  message: string;
}

export const makeSerial = (payload: MakeSerialRequest) => {
  return axiosInstance.post<MakeSerialResponse>("/host", payload);
};

export type GetSerialListRequestType =
  | "id"
  | "type"
  | "sr_code"
  | "limit"
  | "count"
  | "host_code"
  | "username"
  | "nickname"
  | "site_domain"
  | "access_url"
  | "reg_date"
  | "expire"
  | "status";

export type GetSerialListRequestOrder = "desc" | "asc";

export interface GetSerialListRequest {
  target: "host" | "guest";
  /**
   * 검색어
   */
  search?: string;
  /**
   * 정렬대상
   */
  type?: GetSerialListRequestType;
  /**
   * 정렬방법(desc: 오름차순, asc: 내림차순)
   */
  order?: GetSerialListRequestOrder;
  /**
   * 페이지 당 갯수
   */
  limit: number;
  /**
   * 페이지
   */
  page: number;
}

export type FdStatus = "active" | "inactive";

export interface GetSerialListResponseData {
  fd_id: number;
  fd_type: "host";
  fd_sr_code: string;
  fd_limit: number;
  fd_count: number;
  fd_host_code: string;
  fd_username: string;
  fd_nickname: string;
  fd_site_domain: string;
  fd_access_url: string;
  fd_reg_date: Date;
  fd_expire: Date;
  fd_status: FdStatus;
}

export interface GetSerialListResponse {
  result: boolean;
  data: GetSerialListResponseData[];
  total_count: number;
  page: number;
  limit: number;
}

export const getSerialList = ({ target, ...payload }: GetSerialListRequest) => {
  return axiosInstance.get<GetSerialListResponse>(`/admin/${target}`, {
    params: payload,
  });
};

export interface DeleteSerialRequest {
  qr_code: string;
}

export interface DeleteSerialResponse {
  result: boolean;
  message: string;
}

export const deleteSerial = (payload: DeleteSerialRequest) => {
  return axiosInstance.delete<DeleteSerialResponse>("/host", { data: payload });
};

export interface EditSerialRequest {
  qr_code: string;
  company: string;
  expire: string;
  status: FdStatus;
}

export interface EditSerialResponse {
  result: boolean;
  message: string;
}

export const editSerial = (payload: EditSerialRequest) => {
  return axiosInstance.put<EditSerialResponse>("/host", payload);
};
