import { ChangeEventHandler, MouseEventHandler, useState } from "react";
import { postAdminLogin, PostAdminLoginRequest } from "../apis/login";

const useLogin = () => {
  const [loginData, setLoginData] = useState<PostAdminLoginRequest>({
    username: "",
    pw: "",
  });

  /**
   * 로그인 데이터 입력 처리
   * @param event
   */
  const handleLoginData:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined = (event) => {
    event.preventDefault();

    const { name, value } = event.target;
    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  /**
   * 로그인 처리
   * @param event
   */
  const handleLogin: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.preventDefault();

    if (!loginData.username || !loginData.pw) {
      alert("please enter username and password");
    }

    try {
      const { data } = await postAdminLogin(loginData);

      if (!data?.access_token) {
        alert(data?.message);
      } else {
        sessionStorage.setItem("auth", data?.access_token);
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 로그아웃 처리
   * @param event
   */
  const handleLogout:
    | MouseEventHandler<HTMLButtonElement | HTMLLIElement>
    | undefined = (event) => {
    event.preventDefault();

    sessionStorage.removeItem("auth");
    window.location.href = "/";
  };

  return {
    handleLoginData,
    handleLogin,
    handleLogout,
  };
};

export default useLogin;
