import Box from "@mui/material/Box";
import { Container, Paper, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import Logo from "../resources/img/any_ci.svg";
import CopyRights from "../resources/img/copyrights.svg";
import useLogin from "../hooks/useLogin";

const Login = () => {
  const { handleLogin, handleLoginData } = useLogin();

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, backgroundColor: "#f8f9fa", p: 3, height: "100vh" }}
    >
      <Container maxWidth="sm">
        <DemoPaper sx={{ padding: 6, textAlign: "center" }}>
          <img src={Logo} width={"40%"} alt="Logo" />
          <TextField
            type="text"
            id="username"
            name="username"
            onChange={handleLoginData}
            label="Username"
            variant="standard"
            sx={{ width: "100%", marginBottom: 4, marginTop: 3 }}
          />
          <TextField
            type="password"
            id="pw"
            name="pw"
            onChange={handleLoginData}
            label="Password"
            variant="standard"
            sx={{ width: "100%", marginBottom: 4 }}
          />
          <Button
            variant="contained"
            sx={{ marginBottom: 6 }}
            onClick={handleLogin}
          >
            Login
          </Button>
          <br></br>
          <img src={CopyRights} width={"90%"} alt="CopyRights"></img>
        </DemoPaper>
      </Container>
    </Box>
  );
};

export default Login;

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "49vh",
  padding: theme.spacing(2),
  borderRadius: "20px",
  marginTop: "25vh",
  ...theme.typography.body2,
  textAlign: "center",
}));
