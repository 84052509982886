import * as React from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { items } from "../../interfaces/admin";

interface Column {
  id: "username" | "level" | "company" | "status" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "username", label: "Username", minWidth: 170 },
  { id: "level", label: "Grade", minWidth: 100 },
  {
    id: "company",
    label: "Company",
    minWidth: 170,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
  },
  // {
  //   id: "action",
  //   label: "Action",
  //   minWidth: 170
  // },
];

interface Data {
  username: string;
  level: string;
  company: string;
  status: string;
  // action: any;
}

function createData(
  username: string,
  level: string,
  company: string,
  status: string,
): Data {
  // const action = status ==="active"?<Button color="primary">Inactive</Button>:<Button>Active</Button>;
  return { username, level, company, status };
}

export default function TableComponent() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState<any[]>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const listAdmin = async () => {
    let list: any[] = [];

    const result = await axios.get(`${process.env.REACT_APP_API}/admin`);
    const res: items[] = JSON.parse(JSON.stringify(result.data.data));

    res.forEach(function (item: items) {
      list.push(
        createData(
          item.fd_username,
          item.fd_level,
          item.fd_company,
          item.fd_status,
        ),
      );
    });

    setRows(list);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    listAdmin();

    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    setTimeout(() => {
      listAdmin();
    }, 400);
  }, []);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 5 }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
