import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

export interface CommonContextType {
  isBackdrop: boolean;
  setIsBackdrop: Dispatch<SetStateAction<boolean>>;
}

const initialValue: CommonContextType = {
  isBackdrop: false,
  setIsBackdrop: () => {},
};

const CommonContext = createContext<CommonContextType>(initialValue);

export interface CommonContextProviderProps {
  children: ReactNode;
}

export const CommonContextProvider = ({
  children,
}: CommonContextProviderProps) => {
  const [isBackdrop, setIsBackdrop] = useState<boolean>(false);

  return (
    <CommonContext.Provider value={{ isBackdrop, setIsBackdrop }}>
      {children}
    </CommonContext.Provider>
  );
};

export const useCommonContext = () => useContext(CommonContext);
