import { Route, Routes } from "react-router-dom";
import AdminComponent from "./admin";
import SerialComponent from "./serial";
import GuestComponent from "./guest";
import LoginComponent from "./login";

const Pages = () => {
  if (sessionStorage.getItem("auth")) {
    return (
      <Routes>
        <Route path="/" element={<AdminComponent />} />
        <Route path="/admin" element={<AdminComponent />} />
        <Route path="/serial" element={<SerialComponent />} />
        <Route path="/guest" element={<GuestComponent />} />
        <Route path="/report" element={<SerialComponent />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/*" element={<LoginComponent />} />
    </Routes>
  );
};

export default Pages;
