import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  EditSerialRequest,
  GetSerialListRequestOrder,
  GetSerialListRequestType,
  GetSerialListResponseData,
} from "../../apis/serial";
import { Column } from "../../pages/serial";
import { ChangeEventHandler, MouseEventHandler } from "react";
import TablePagination from "@mui/material/TablePagination";
import TableHeaderColumnOrder from "./tableHeaderColumnOrder";
import { Checkbox } from "@mui/material";

export interface TableComponentProps {
  columns: Column[];
  rows: GetSerialListResponseData[];
  totalCount: number;
  handleDeleteRow: (qr_code: string) => void;
  handleEditModalOpen: (row: any) => void;
  handleStatus: (payload: EditSerialRequest) => Promise<void>;
  page: number;
  limit: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  handleChangeLimit:
    | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  order: GetSerialListRequestOrder;
  setOrder: React.Dispatch<React.SetStateAction<GetSerialListRequestOrder>>;
  type: GetSerialListRequestType;
  setType: React.Dispatch<React.SetStateAction<GetSerialListRequestType>>;
  handleCopy: (value: any) => void;
  handleChecked: (fd_sr_code: string) => void;
  handleCheckedAll: MouseEventHandler<HTMLTableCellElement> | undefined;
  checkRows: string[];
  handleResetCheckRows: () => void;
  isFunction?: boolean;
}

const TableComponent = ({
  page,
  limit,
  columns,
  rows,
  totalCount,
  handleDeleteRow,
  handleEditModalOpen,
  handleStatus,
  handleChangePage,
  handleChangeLimit,
  order,
  setOrder,
  type,
  setType,
  handleCopy,
  checkRows,
  handleChecked,
  handleCheckedAll,
  handleResetCheckRows,
  isFunction,
}: TableComponentProps) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (column.id === "checkbox") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        width: column.width,
                      }}
                      onClick={handleCheckedAll}
                    >
                      <Checkbox checked={checkRows.length === rows.length} />
                    </TableCell>
                  );
                }

                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      width: column.width,
                    }}
                  >
                    <div
                      style={{
                        ...(column.orderAble ? styles.tableHeaderColumn : {}),
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                      <TableHeaderColumnOrder
                        column={column}
                        order={order}
                        setOrder={setOrder}
                        type={type}
                        setType={setType}
                        handleResetCheckRows={handleResetCheckRows}
                      />
                    </div>
                  </TableCell>
                );
              })}
              {isFunction ? (
                <TableCell align="center">Function</TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.fd_sr_code}
                >
                  {columns.map((column) => {
                    const value = column.format
                      ? column.format((row as any)[column.id], row)
                      : (row as any)[column.id];

                    if (column.id === "checkbox") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {" "}
                          <Checkbox
                            checked={checkRows.includes(row.fd_sr_code)}
                            onClick={(event) => {
                              event.preventDefault();
                              handleChecked(row.fd_sr_code);
                            }}
                          />
                        </TableCell>
                      );
                    }

                    if (column.id === "no") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {totalCount - page * limit - index}
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        onClick={(event) => {
                          event.preventDefault();
                          if (!column?.isCopy) return;
                          handleCopy(value);
                        }}
                      >
                        <div
                          style={{
                            ...(column.eclipse ? styles.eclipseArea : {}),
                            width: column.width,
                          }}
                          title={value}
                        >
                          {value}
                        </div>
                      </TableCell>
                    );
                  })}
                  {isFunction ? (
                    <TableCell>
                      <div style={styles.functionArea}>
                        {row.fd_status === "active" ? (
                          <>
                            <Button
                              type="button"
                              variant="contained"
                              color="error"
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteRow(row.fd_sr_code);
                              }}
                            >
                              Delete
                            </Button>
                            <Button
                              type="button"
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                handleEditModalOpen(row);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              type="button"
                              variant="contained"
                              onClick={async (e) => {
                                e.preventDefault();
                                await handleStatus({
                                  qr_code: row.fd_sr_code,
                                  company: row.fd_site_domain,
                                  expire: new Date(row.fd_expire || "")
                                    .toISOString()
                                    .replace(/T/g, " ")
                                    .replace("Z", ""),
                                  status: "inactive",
                                });
                              }}
                            >
                              Lock
                            </Button>
                          </>
                        ) : (
                          <Button
                            type="button"
                            variant="contained"
                            onClick={async (e) => {
                              e.preventDefault();
                              await handleStatus({
                                qr_code: row.fd_sr_code,
                                company: row.fd_site_domain,
                                expire: new Date(row.fd_expire || "")
                                  .toISOString()
                                  .replace(/T/g, " ")
                                  .replace("Z", ""),
                                status: "active",
                              });
                            }}
                          >
                            Unlock
                          </Button>
                        )}
                      </div>
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 30, 50, 100, 200, 300]}
        component="div"
        count={totalCount}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeLimit}
      />
    </Paper>
  );
};

export default TableComponent;

const styles = {
  eclipseArea: {
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  functionArea: {
    display: "flex",
    gap: 8,
    justifyContent: "center",
  },
  tableHeaderColumn: {
    display: "flex",
    justifyContent: "center",
    gap: 3,
  },
  orderButton: {
    cursor: "pointer",
  },
};
