import * as React from "react";

import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { AccountBox, QrCode } from "@mui/icons-material";
import AnyLogo from "../../resources/img/any-logo.svg";
import { Link } from "react-router-dom";
const drawerWidth = 240;

export default function SideMenuBar() {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar sx={{ textAlign: "center" }}>
        <img src={AnyLogo} width={"100%"} alt="anychat" />
      </Toolbar>
      <List>
        <Link to={"/admin"} style={{ textDecoration: "none", color: "#000" }}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AccountBox />
              </ListItemIcon>
              <ListItemText primary={"Admin"} />
            </ListItemButton>
          </ListItem>
        </Link>
        {/* <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Analytics />
            </ListItemIcon>
            <ListItemText primary={"Analystics"} />
          </ListItemButton>
        </ListItem> */}
      </List>

      <Divider />
      <List>
        <Link to={"/serial"} style={{ textDecoration: "none", color: "#000" }}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <QrCode></QrCode>
              </ListItemIcon>
              <ListItemText primary={"Serial Code(Host)"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/guest"} style={{ textDecoration: "none", color: "#000" }}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <QrCode></QrCode>
              </ListItemIcon>
              <ListItemText primary={"Serial Code(Guest)"} />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
}
