import { ChangeEventHandler, MouseEventHandler, useState } from "react";
import { postAdmin, PostAdminRequest } from "../apis/admin";
import { SelectChangeEvent } from "@mui/material/Select";

const useAdmin = () => {
  const [createAdminData, setCreateAdminData] = useState<PostAdminRequest>({
    company: "",
    level: "",
    pw: "",
    pw_check: "",
    username: "",
  });

  const handleCreateAdminData:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined = (event) => {
    event.preventDefault();

    const { name, value } = event.target;
    setCreateAdminData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectCreateAdminData = (event: SelectChangeEvent) => {
    event.preventDefault();

    const { name, value } = event.target;
    setCreateAdminData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePostAdmin:
    | MouseEventHandler<HTMLButtonElement>
    | undefined = async (event) => {
    try {
      if (!createAdminData?.username?.trim()) {
        alert("please, enter a username");
        return;
      }

      if (!createAdminData?.pw?.trim()) {
        alert("please, enter a password");
        return;
      }

      if (!createAdminData?.pw_check?.trim()) {
        alert("please, enter a password check");
        return;
      }

      if (createAdminData?.pw?.trim() !== createAdminData?.pw_check?.trim()) {
        alert("please, check the password");
        return;
      }

      if (!createAdminData?.company?.trim()) {
        alert("please, enter a company");
        return;
      }
      if (!createAdminData?.level) {
        alert("please, enter a level");
        return;
      }

      const { data } = await postAdmin(createAdminData);

      if (!data.result) {
        alert(data.message);
        return;
      } else {
        alert(data.message);
        window.location.href = "/admin";
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    createAdminData,
    handleCreateAdminData,
    handleSelectCreateAdminData,
    handlePostAdmin,
  };
};

export default useAdmin;
