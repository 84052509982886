import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import useAdmin from "../../hooks/useAdmin";

interface DialogComponentProps {
  isOpen: boolean;
  Transition: any;
  handleModalClose: any;
}

export default function DialogComponent({
  isOpen,
  Transition,
  handleModalClose,
}: DialogComponentProps) {
  const {
    createAdminData,
    handleCreateAdminData,
    handleSelectCreateAdminData,
    handlePostAdmin,
  } = useAdmin();

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Create Admin"}</DialogTitle>
      <DialogContent>
        <div id="alert-dialog-slide-description">
          <TextField
            type="text"
            id="username"
            name="username"
            label="Username"
            variant="standard"
            sx={{ width: "100%", marginBottom: 3 }}
            value={createAdminData.username}
            onChange={handleCreateAdminData}
          />
          <TextField
            type="password"
            id="pw"
            name="pw"
            label="Password"
            variant="standard"
            sx={{ width: "100%", marginBottom: 3 }}
            value={createAdminData.pw}
            onChange={handleCreateAdminData}
          />
          <TextField
            type="password"
            id="pw_check"
            name="pw_check"
            label="Checking Password"
            variant="standard"
            sx={{ width: "100%", marginBottom: 3 }}
            value={createAdminData.pw_check}
            onChange={handleCreateAdminData}
          />
          <FormControl
            variant="standard"
            sx={{ width: "100%", marginBottom: 2 }}
          >
            <InputLabel id="LEVEL">Level</InputLabel>
            <Select
              labelId="LEVEL"
              name="level"
              id="level"
              label="Level"
              value={createAdminData.level}
              onChange={handleSelectCreateAdminData}
            >
              <MenuItem value={3}>Master</MenuItem>
              <MenuItem value={2}>Manager</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ width: "100%", marginBottom: 2 }}
          >
            <InputLabel id="COMPANY">Company</InputLabel>
            <Select
              labelId="COMPANY"
              id="demo-simple-select-standard"
              name="company"
              value={createAdminData.company}
              onChange={handleSelectCreateAdminData}
              label="Company"
            >
              <MenuItem value={""}>All</MenuItem>
              <MenuItem value={"a0001"}>업체1</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ width: "100%", marginBottom: 2 }}
          >
            <Button
              variant="contained"
              sx={{ marginTop: 4 }}
              onClick={handlePostAdmin}
            >
              Make Admin
            </Button>
          </FormControl>
        </div>
      </DialogContent>
    </Dialog>
  );
}
